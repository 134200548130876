@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

:root {
    box-sizing: border-box;
    font-family: 'Lato', sans-serif; 
    color: darkblue;
}

*, 
::before,
::after {
    box-sizing: inherit;
}

h1, h2, h3, h4, h5 {
    color: darkblue;
    display: flex;
    align-items: center;
    justify-content: center;
}
   
.pagebody {
    background-repeat: no-repeat;
    background: linear-gradient(#118ab2, #06d6a0,#ffd166);
    color: darkblue;

    line-height: 1.5;
    font-weight: bold;
}

hr {
    border-top: 1px solid #00563B;
}

.travel {
    color: darkblue;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1em;
}
