/* SITE COMMON STYLES */
html, body {
	color: darkgray;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
} 
#root{
	background-color: transparent;
}

h1 {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 24px;
	font-style: normal;
	font-variant: normal;
	font-weight: 500;
	line-height: 26.4px;
}
h3 {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 14px;
	font-style: normal;
	font-variant: normal;
	font-weight: 500;
	line-height: 15.4px;
}
h4 {
	font-style: normal;
	font-variant: normal;
	font-weight: 600;
}

.closerSpacingBottom {
	margin-bottom: -.5em
}

.closerSpacingTop {
	margin-top: -.6em
}
p {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 14px;
	font-style: normal;
	font-variant: normal;
	font-weight: 400;
	line-height: 20px;
	padding-bottom:12px;
}

.text-dark { 
  color:#000;
}

.navigation {
	display: flex;
	justify-content: flex-end;
	margin-top: 25px;
}

.navigation > li {
	text-transform: uppercase;
	font-size: 22px;
	padding: 16px;
	font-weight: 43;
	display: inline;
}

.regLink {
	color: #000;
}

.homeLink {
	color: #FFF;
}

a:link {
	text-decoration: none;
}

/* visited pseudo class not working for the content email need to figure that out*/
a:visited { 
	text-decoration: none;
}

a:hover {
	color:#38cdf2;
	text-decoration: underline;
}

/* HOME STYLES */
#homepageBackground {
	background: url('./assets/cover/mpls_bw.png');
	position:fixed;
    padding:0;
    margin:0;
    top:0;
	left:0;
	z-index: -1;
    width: 100%;
    height: 100%;
}

#welcome {
	text-align: center;
	color:#FFF;
	font-weight: 530;
	line-height: 65px;
	font-size: 85px;
	padding-top: 85px;
	margin-bottom: 35px;
}

#welcomeMessage {
	text-align: center;
	font-size: 20px;
	color:#FFF;
}

/*FOOTER STYLES*/
.footer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
    padding: 6px 2px;
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: #efefef;
    color: #000;
}

.socialIcon {
	padding: 4px;
}

.socialIcon > img {
	width: 25px;
	height: 25px;
	border-radius: 2px;
}


/* RESUME PAGE STYLES */
.resumeDownload {
	margin-left: 10px;
}

.resumeContainer {
	display: flex;
}

.resumeLeft{
	background-color: #596064;
	color: #FFF;
}
.resumeContentHolder {
	margin: 10px;
}

.sectionTextMargins {
	margin-left: 15px;
}

.resumeRight{
	width: 74%;
	color: #010211;
}

hr {
	border: 1px solid #010211;
}


@media screen and (min-width: 380px) and (max-width: 400px) {
	h1 {
		font-size: 20px;
	}
	h3 {
		font-size: 10px;
	}
	#welcome {
		font-size: 60px;
	}
	.navigation {
		justify-content: space-evenly;
	}	
}

@media screen and (min-width: 420px) and (max-width: 600px) {
	.navigation {
		justify-content: space-evenly;
	}
}